<template>
  <section class="cont colorSet">
    <el-row class="crumbs-box">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>PMS</el-breadcrumb-item>
        <el-breadcrumb-item>住宿设置</el-breadcrumb-item>
        <el-breadcrumb-item>色卡设置</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>

    <el-row class="content-box">
      <el-row class="table-box">
        <el-table :data="tableData" border fit :stripe="true">
          <el-table-column type="index" width="100" label="序号"></el-table-column>
          <el-table-column prop="name" label="房间状态" min-width="30"></el-table-column>
          <el-table-column label="目前色卡颜色" min-width="30">
            <template slot-scope="scope">
              <div class="color_box" :style="{ background: scope.row.color }"></div>
            </template>
          </el-table-column>
          <el-table-column label="操作" min-width="30">
            <template slot-scope="scope">
              <el-button type="text">
                <el-link type="primary" @click="setColor(scope.row)">更改颜色</el-link>
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
    </el-row>

    <!-- 更改颜色弹窗 -->
    <el-dialog title="更改颜色" :visible.sync="isShow" width="30%" @close="closeDialog">
      <div class="label">
        选择状态
        <span class="ml-20">{{ name }}</span>
      </div>
      <div class="current_color_box">
        <div class="current_color">当前颜色</div>
        <div class="color_box" :style="{ background: color }"></div>
      </div>
      <div class="set_color">
        <div class="label">更改颜色</div>
        <el-color-picker v-model="color" :predefine="predefineColors" size="medium"></el-color-picker>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isShow = false">取消</el-button>
        <el-button type="primary" @click="save">确认</el-button>
      </span>
    </el-dialog>
  </section>
</template>

<script>
  import { axios_getConfigList, axios_saveConfig } from '@/api/pms/atrialState/atrialState'
  import { mapState } from 'vuex'
  export default {
    data() {
      return {
        tableData: [
          { name: '空房', color: '#0099ff', code: 'EMPTY' },
          { name: '脏房', color: '#cccccc', code: 'DIRTY' },
          { name: '预定', color: '#ffd4b3', code: 'CHECK_INT' },
          { name: '预离', color: '#c2b3ff', code: 'CHECK_OUT' },
          { name: '入住', color: '#b3deff', code: 'LIVE' },
          { name: '关房', color: '#cccccc', code: 'CLOSE' },
        ],
        isShow: false,

        name: '',
        color: '',
        row: {},
        // 预选色
        predefineColors: ['#ff4500', '#ff8c00', '#ffd700', '#90ee90', '#00ced1', '#1e90ff', '#c71585', '#FF7800', '#FAD400', '#00BABD'],
      }
    },
    computed: {
      ...mapState(['hotelInfo']),
    },
    mounted() {
      this.getDate()
    },
    methods: {
      // 获取色卡列表
      getDate() {
        const data = {
          hotelId: this.hotelInfo.id, // 酒店ID
        }
        axios_getConfigList(data).then(({ success, records }) => {
          if (success && records.length) {
            let list = [...this.tableData]
            // 处理请求回来的数据
            for (let inx = 0; inx < records.length; inx++) {
              if (list.some((item) => item.name === records[inx].name)) {
                list.some((item, index) => {
                  if (item.name === records[inx].name) {
                    list[index] = records[inx]
                  }
                })
              }
            }
            this.$nextTick(() => {
              this.tableData = list
            })
          }
        })
      },
      // 关闭弹窗时触发
      closeDialog() {
        this.isShow = false
      },
      // 更改颜色
      setColor(item) {
        this.isShow = true
        this.color = item.color
        this.name = item.name
        this.row = item
      },
      // 保存颜色
      save() {
        if (this.color) {
          const data = {
            hotelId: this.hotelInfo.id, // 酒店ID
            code: this.row.code,
            name: this.row.name,
            color: this.color,
          }
          axios_saveConfig(data).then(({ success }) => {
            if (success) {
              this.$message.success('设置成功!')
              this.closeDialog()
              this.getDate()
            }
          })
        } else {
          this.$message.error('请选择颜色后保存!')
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .colorSet {
    .ml-20 {
      margin-left: 20px;
    }
    .label {
      margin: 0 20px;
      font-size: 16px;
    }
    .color_box {
      width: 20px;
      height: 20px;
    }
    .current_color_box {
      display: flex;
      margin: 20px 0;
      .current_color {
        margin: 0px 25px 0 20px;
        font-size: 16px;
      }
    }
    .set_color {
      display: flex;
      align-items: center;
    }
  }
</style>
